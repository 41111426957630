@import "shared";

.brand {
  padding: 40px 0 24px;
  position: absolute;
  top: 0;
  width: 100%;
}

.layout--modal {
  .brand {
    left: 0;
    padding: em(32) em(24) 0 em(24);
    z-index: 1;
  }

  .brand__name {
    max-width: em(150);
  }
}

.layout--redirect {
  .brand__name {
    max-width: em(190);
  }
}
