@import "default";

&_template {
  &_guest {
    .layout--new-interface {
      .guest-email {
        margin: em(32) 0 em(14.5) 0;

        .title {
          margin-bottom: 16px;
          font-size: $cow-font-18;
          font-weight: 600;
          line-height: 1.3;
        }
      }

        .bank_interests {
          margin: $cow-spacing-32 0 $cow-spacing-32 0;
        }

      .card-list {
        .email-card-option--is-not-modifiable {
          cursor: unset !important;
        }

        // TODO: Remove this after correct the Cow Components CardOption styles for icon wrapper max height and width
        .email-card-option--is-modifiable {
          .cow-icon-wrapper svg {
            max-height: em(20) !important;
            max-width: em(20) !important;
          }
        }

        .card-option:last-of-type {
          margin-bottom: 24px;
        }
      }

      .content--guest {
        margin-top: em(32);

        #card-option__spei {
          margin-top: em(42);
          margin-bottom: $cow-spacing-32;
        }
      }
    }
  }
}
